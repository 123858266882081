.orion__footer {
  height: 30vh;
  padding: 5vh 4vw;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--color-section-border);
}

.orion__footer small {
  color: var(--color-text);
}

.orion__footer-logo {
  width: 200px;
}

.orion__footer-link {
  cursor: pointer;
  text-decoration: underline;
}

.orion__footer-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orion__social-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.orion__contact-info {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.orion__social-container img {
  width: 24px;
}
.orion__footer-message-policy {
  display: flex;
  gap: 0.5rem;
}

@media (max-width: 1000px) {
  .orion__footer {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .orion__footer-section {
    gap: 2rem;
    align-items: center;
  }
  .orion__contact-info {
    align-items: center;
    padding-bottom: 2rem;
  }
}
