.orion__about-us {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.orion__about-us a {
  text-decoration: none;
}

.orion__about-us p {
  width: 70%;
  font-weight: 600;
}

.orion__about-us h2 {
  font-weight: 600;
  color: var(--color-text);
}
.orion__about-us img {
  width: 50px;
}

.orion__our-history {
  display: flex;
  flex-direction: column;
  padding: 80px;
  background-image: linear-gradient(
    336deg,
    #020536 0%,
    #020536 35%,
    #ce154e 76%,
    #ffa332 99%
  );
  gap: 4rem;
  width: 100%;
}

.orion__our-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orion__our-history-header-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: right;
  width: 70%;
}

.orion__our-history h1 {
  font-size: 48px;
  font-weight: 800;
  color: var(--color-text);
}

.orion__our-history-column {
  padding: 40px 80px;
  gap: 4rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.orion__our-history-column h2 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-text);
}

.orion__profile {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 400px;
  margin-right: 4rem;
  text-align: center;
}

.orion__profile:nth-child(3n) {
  margin-right: 0;
}

.orion__profile-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.orion__profile img {
  width: 250px;
  height: 380px;
  border-radius: 4px;
  object-fit: cover;
}

.orion__our-vision {
  display: flex;
  flex-direction: column;
  padding: 80px;
  background-image: linear-gradient(
    336deg,
    #020536 0%,
    #020536 35%,
    #ce154e 76%,
    #ffa332 99%
  );
  gap: 4rem;
  width: 100%;
}

.orion__our-vision-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orion__our-vision-header-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: right;
  width: 70%;
}

.orion__our-vision h1 {
  font-size: 48px;
  font-weight: 800;
  color: var(--color-text);
}

.orion__our-vision p {
  font-size: 20px;
  color: var(--color-text);
}

.orion__our-values {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.orion__our-values-header {
  display: flex;
  flex-direction: column;
  padding: 80px;
  background-image: linear-gradient(
    336deg,
    #020536 0%,
    #020536 35%,
    #ce154e 76%,
    #ffa332 99%
  );
  gap: 4rem;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.orion__our-values-header h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--color-text);
}

.orion__value {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  background-color: var(--color-sectionbg);
  border-radius: 10px;
  border: 1px solid var(--color-sectionbg);
}

.orion__value.right p {
  text-align: left;
}

.orion__value h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--color-text);
}

.orion__value p {
  text-align: right;
  width: 60%;
  font-size: 16px;
}

.orion__value-header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.orion__value-icon {
  width: 30px;
  color: var(--color-brandpink);
  transition: 0.2s ease-in-out;
}

.orion__value-icon:hover {
  color: var(--color-brandyellow);
}

.orion__join-us {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
}

.orion__join-us h1 {
  color: var(--color-text);
  font-weight: 700;
  font-size: 48px;
}

.orion__join-us h2 {
  color: var(--color-brandpink);
}
@media only screen and (max-width: 780px) {
  .orion__our-history-header {
    flex-direction: column;
    gap: 2rem;
  }

  .orion__our-history-header-content {
    text-align: left;
    width: 100%;
  }

  .orion__our-vision-header {
    flex-direction: column;
    gap: 2rem;
  }

  .orion__our-vision-header-content {
    text-align: left;
    width: 100%;
  }

  .orion__profile img {
    object-fit: fill;
  }
}
