* {
  box-sizing: border-box;
}

.orion__homepage {
  color: var(--color-text);
}

.orion__content {
  display: flex;
  padding: 2.5vh 2.5vw;
  gap: 0.625rem;
  justify-content: center;
}

.orion__section-container {
  display: flex;
  padding: 1.75em;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;
  width: 100%;

  border-radius: 0.25rem;
  background-color: var(--color-sectionbg);
  border: 1px solid var(--color-section-border);
}

.orion__open-strategies {
  width: 100%;
  max-height: 380px;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  overflow-y: auto;
}

.orion__left-column {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  min-width: 30%;
}

.orion__right-column {
  display: flex;
  min-width: 65%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.orion__date-container {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
  flex-direction: row;
  width: 100%;
}

.orion__subscription-alert {
  background-color: var(--color-alert);
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
}

.orion__subscription-alert {
  background-color: var(--color-alert);
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
}

.orion__subscription-alert-text {
  display: flex;
  gap: 0.25rem;
}

.orion__subscription-close-icon {
  cursor: pointer;
}

.orion__loading {
  margin: 3.125rem auto;
}

.orion__open-strategies-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  right: 0;
  cursor: pointer;
}

.orion__open-strategies-link small {
  color: var(--color-text);
}

.orion__right-arrow {
  width: 18px;
}

@media (max-width: 1000px) {
  .orion__content {
    flex-direction: column-reverse;
  }

  .orion__date-container {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .orion__date-container {
    flex-direction: column;
  }
}

.orion__open-strategies-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 350px;
  overflow-y: scroll;
}

.orion__tooltip-container {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.orion__disclaimer {
  text-align: justify;
  font-size: 10px;
}

.orion__title-container {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__content {
    padding: 40px 120px !important;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__content {
    padding: 40px 240px !important;
  }
}
