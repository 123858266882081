.orion__chart-info {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-sectionbg);
  padding: 1rem;
  border-radius: 0.25rem;
  height: 3.125rem;
  align-items: center;
  border: 1px solid var(--color-section-border);
}

.orion__pair-info {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
}

.orion__time-period {
  display: flex;
  gap: 1.5rem;
}

.orion__info-column {
  display: flex;
  flex-direction: column;
}

.orion__column-text {
  color: var(--color-subtext);
}

.orion__column-title {
  color: var(--color-text);
}
