.orion__hint {
  position: relative;
  display: inline-block;
}

.orion__hint-bubble {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0;
  transform: translateX(-10%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  white-space: nowrap;
}

.orion__hint:hover .orion__hint-bubble {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .orion__hint-bubble {
    font-size: 10px;
    padding: 3px 6px;
  }
}
