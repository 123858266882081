@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

:root {
  --font-family: "Mulish", sans-serif;

  --color-lighttext: #141414;
  --color-bg: #020536;
  --color-sectionbg: #0f153a;
  --color-lightblue: #232f56;
  --color-section-children: #0b0f2e;
  --color-section-border: #171e4b;
  --color-text: #e6e5f0;
  --color-subtext: #9592a8;
  --color-greentext: #15ce6a;
  --color-greentext-60: #5bec4560;
  --color-greenlight: #24a710;
  --color-brandpink: #ce154e;
  --color-brandpink-darker: #a3113d;
  --color-brandyellow: #ffa332;
  --color-brandorange: #f9853f;
  --color-pink-text: #ff1962;
  --color-pink-shadow: #fe135d40;
  --color-yellow-shadow: #fcab4880;
  --color-blue-shadow: #252b7380;
  --color-brandpurple: #980568;
  --color-alert: #871212;
  --color-accent: #171717;
  --color-redtext: #ff595d;

  --text-gradient: linear-gradient(
    90deg,
    var(--color-brandpink) 0%,
    var(--color-brandorange) 100%
  );

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

img {
  pointer-events: none;
}

h1,
.h1 {
  font-size: 28px;
  font-weight: 500;
}

h2,
.h2 {
  font-size: 23px;
  font-weight: 600;
}

h3,
.h3 {
  font-size: 19px;
}

h4,
.h4 {
  font-size: 16px;
}

h5,
.h5 {
  font-size: 16px;
}

hr,
.hr {
  border: 1px solid;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
  overflow-x: none;
}

::-webkit-scrollbar-track {
  border-radius: 0.3em;
  background: transparent;
  margin-block: 0.25em;
}

::-webkit-scrollbar-thumb {
  background: var(--color-text);
  border-radius: 0.3em;
}

.orion__default-button {
  border: none;
  background-color: var(--color-brandpink);
  border-radius: 4px;
  padding: 12px;
  color: var(--color-text);
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  transition: 0.25s ease-in-out;
  text-align: center;
  box-shadow: -1px 0px 20px -2px #00000020;
  -webkit-box-shadow: -1px 0px 20px -2px #00000020;
  -moz-box-shadow: -1px 0px 20px -2px #00000020;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orion__default-button:hover {
  color: var(--color-text);
  background-color: var(--color-brandpink-darker);
  /* transform: scale(1.05); */
}
