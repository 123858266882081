.orion__section-indicator-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.orion__section-indicator {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 3.75rem;
}

.orion__circle-filled {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background-color: var(--color-brandpink);

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

.orion__circle-alert {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background-color: var(--color-alert);

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

.orion__circle-outlined-pink {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-brandpink);
  border: 2px solid var(--color-brandpink);
}

.orion__circle-outlined-gray {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-subtext);
  border: 2px solid var(--color-subtext);
}

.orion__section-name {
  font-size: 10px;
  font-weight: 600;

  color: var(--color-brandpink);
  text-align: center;
}

.orion__section-name.alert {
  color: var(--color-alert);
}

.orion__line-container {
  width: 50%;
  display: flex;
  align-items: center;
}

.orion__line-gray {
  width: 100%;
  height: 1.5px;
  color: var(--color-subtext);
}

.orion__line-pink {
  width: 100%;
  height: 1px;
  color: var(--color-brandpink);
}
