.orion__fs-search-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orion__uc-telegram-button {
  background-color: var(--color-brandpink);
  width: 150px;
  padding: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  color: var(--color-text);
  font-weight: 600;
  cursor: pointer;
}

.orion__uc-telegram-status-message {
  display: flex;
  gap: 0.4rem;
}

.orion__telegram-status.active {
  color: var(--color-greentext);
}

.orion__telegram-status.disabled {
  color: var(--color-alert);
}
