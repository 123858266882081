.orion__floating-alert {
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;

    max-height: 50px;
    max-width: 90%;
    padding: 1rem;
    border-radius: .25rem;
    background-color: var(--color-greenlight);

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.orion__floating-alert small {
    color: var(--color-text);
    font-weight: 600;
    width: fit-content;
    white-space: nowrap;
}

.orion__floating-alert-icon {
    color: var(--color-text);
    width: 24px;
}