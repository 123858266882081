.orion__open-strategies-content {
  display: flex;
  flex-direction: column;
  padding: 0px 4vw 3.5vh;
  gap: 1.5rem;
  justify-content: center;
  color: var(--color-text);
  width: 100%;
}

.orion__strategies-header {
  position: sticky;
  top: 64px;
  background-color: var(--color-bg);
  width: 100%;
  padding: 3.5vh 4vw 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 15vh;
  z-index: 2;
}

.orion__strategies-header h1 {
  color: var(--color-brandpink);
}

.orion__strategies-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
}

.orion__strategies-return-button {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border: none;
  background-color: var(--color-brandpink);
  text-align: center;
  cursor: pointer;
}

.orion__strategies-return-button .icon {
  width: 1.2rem;
  height: 1.2rem;
  color: white;
}

.orion__strategies-table {
  border-collapse: collapse;
  border-spacing: 15px 0px;
  margin: 0px -15px;
  table-layout: fixed;
}

.orion__strategies-table thead tr {
  text-align: left;
  border-bottom: 2px solid var(--color-section-border);
}

.orion__strategies-table thead {
  position: sticky;
  top: calc(64px + 15vh);
  border-bottom: 2px solid var(--color-section-border);
  background-color: var(--color-bg);
  z-index: 1;
}

.orion__strategies-table th {
  color: var(--color-subtext);
  font-weight: 500;
  font-size: 12px;
  padding: 0.5rem 1.25rem;
  width: 40%;
  white-space: nowrap;
  table-layout: auto;
}

.orion__strategies-table td {
  padding: 0.8rem 1.25rem;
  font-size: 16px;
  height: fit-content;
  white-space: nowrap;
  border-bottom: 2px solid var(--color-section-border);
}

.orion__strategies-table th:last-child {
  text-align: right;
}

.orion__strategies-table td:last-child {
  text-align: right;
}

.orion__strategies-table td:first-child {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
}

.orion__strategies-table-container {
  width: 100%;
}

.orion__strategies-pair-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.coin-img-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.coin-img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.coin-img-container small {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.orion__table-icon {
  width: 16px;
}

.orion__strategies-tier-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 32px;
  height: 32px;
}

@media only screen and (min-width: 2560px) {
  .orion__open-strategies-content {
    padding: 40px 240px;
  }
}

@media only screen and (max-width: 1000px) {
  .orion__strategies-table-container {
    overflow-x: auto !important;
  }

  .orion__strategies-header {
    padding-top: 1.5vh;
    height: 14vh;
  }

  .orion__strategies-table thead {
    position: static;
  }
}
