.orion__landing-navbar {
  padding: 16px 40px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: sticky;
  background-color: var(--color-bg);
  top: 0;

  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.orion__landing-navbar nav {
  width: 100%;
  position: relative;
}

.orion__navbar-buttons {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.orion__navbar-buttons a.link {
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text);
  cursor: pointer;
  text-decoration: none;
}

.orion__navbar-buttons a.link:hover {
  text-decoration: underline;
}

.orion__landing-nav-logo {
  width: 120px;
}

.orion__lp-menu-icon {
  width: 30px;
  color: var(--color-text);
  cursor: pointer;
}

.orion__lp-navbar-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
}

.orion__lp-navbar-items ul {
  padding-left: 2rem;
  display: flex;
  gap: 2rem;
  list-style-type: none;
}

.orion__lp-navbar-items ul li a {
  font-size: 16px;
  color: var(--color-text);
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .orion__landing-navbar {
    width: 100%;
    justify-content: space-between;
    position: relative;
  }

  .orion__landing-navbar nav {
    width: fit-content;
  }

  .orion__landing-nav-logo {
    width: 40px;
    cursor: pointer;
  }

  .orion__landing-navbar-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    background-color: var(--color-bg);
    height: 100vh;
    width: 70vw;
    border-left: 1.5px solid var(--color-section-border);
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .orion__landing-navbar-sidebar li {
    list-style-type: none;
  }

  .orion__landing-navbar-sidebar li a {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    text-decoration: none;
  }
}

@media only screen and (max-width: 400px) {
  .orion__landing-navbar-sidebar {
    width: 100vw;
  }
}
