.orion__second-section {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
    align-items: center;
    height: fit-content;
}

.orion__second-section-header {
    width: 100%;
}

.orion__return-button {
    width: 2.188rem;
    height: 2.188rem;
    border-radius: 50%;
    border: none;
    background-color: var(--color-brandpink);
    text-align: center;
    position: absolute;
    margin-left: 0px;

    cursor: pointer;
}

.icon {
    height: 1.25rem;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: var(--color-text);
}

.orion__header-text {
    display: flex;
    flex-direction: column;
    gap: .125rem;
    text-align: center;
}

.orion__duration-label,
.orion__value-label {
    color: var(--color-text)
}

.orion__qr-container {
    text-align: center;
}

.orion__qr-container {
    border-radius: .25rem;
    border: 2px solid var(--color-brandpink);
    background-color: white;
    padding: .75rem;
    align-items: center;
}

.orion__qr-code {
    width: 200px;
    height: 200px;
}

.orion__pix-code {
    width: 75%;
    text-align: center;
    word-wrap: break-word;
}

.orion__copy-button {
    background-color: var(--color-bg);
    border: 2px solid var(--color-brandpink);
    border-radius: .25rem;
    display: flex;
    padding: .25rem .5rem;
    gap: .125rem;
    width: fit-content;
    height: 1.625rem;
    align-items: center;
    cursor: pointer;
    transition: background-color .25s;
}

.orion__copy-button:hover {
    background-color: var(--color-brandpink);
}

.orion__copy-button:active {
    background-color: var(--color-brandpurple);
    border: 2px solid var(--color-brandpurple)
}

.orion__copy-button small {
    color: white;
    text-align: left;
}

.orion__copy-icon {
    width: 1.125rem;
    color: var(--color-text);
}