.orion__chart-container {
  background-color: var(--color-sectionbg);
  border: 1px solid var(--color-section-border);
  border-radius: 0.25rem;
  height: 70vh;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.orion__chart-legend-container {
  position: absolute;
  top: 10px;
  display: flex;
  gap: 1rem;
}

.orion__chart-legend {
  display: flex;
  align-items: center;
  gap: 5px;
}

.orion__chart-legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .orion__chart-container {
    height: 60vh;
    width: 100%;
  }
}
