.orion__subscription {
  width: 100vw;
  min-height: 80vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 0px;
}

.orion__subscription-container {
  min-width: 40vw;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow-x: hidden;

  gap: 0.625rem;

  color: var(--color-text);
}

.orion__title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-text);
}

small {
  color: var(--color-subtext);
}

.orion__subscription-section {
  padding: 2rem;
  background-color: var(--color-sectionbg);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orion__container-header {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.orion__plan-not-selected-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: var(--color-alert);
  width: 100%;
  padding: 0.5rem;
  margin-top: -0.7rem;
  border-radius: 0 0 0.25rem 0.25rem;
}

.orion__plan-not-selected-warning small {
  color: var(--color-text);
}

.orion__warning-icon {
  width: 20px;
  color: var(--color-text);
}

.orion__loading {
  align-items: center;
}

@media (max-width: 780px) {
  .orion__subscription-container {
    max-width: 90%;
  }
}

@media (min-width: 780px) and (max-width: 1000px) {
  .orion__subscription-container {
    max-width: 90%;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__subscription-container {
    min-width: 35vw !important;
    max-width: 40vw !important;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__subscription-container {
    min-width: 25vw !important;
    max-width: 30vw !important;
  }
}
