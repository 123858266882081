.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.container {
  width: 100%;
  position: relative;
}

.orion__label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.orion__label-wrapper small {
  color: var(--color-text);
  font-weight: 500;
}

.coin-info {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;

  color: var(--color-text);
}

.coin-img {
  width: 24px;
  height: 24px;
}

.coin-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--color-brandpink);
  border-radius: 50%;
}

.coin-img-container small {
  color: var(--color-text);
}

.pair-selector {
  border: 0;
  border-radius: 4px;
  background: var(--color-section-children);
  height: 5.125rem;
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.button-text h4 {
  font-weight: 400;
  font-size: 1rem;
}

.button-text small {
  font-weight: 500;
  font-size: 0.75rem;
}

.button-icon {
  width: 30px;
  color: var(--color-text);
}

button:hover {
  cursor: pointer;
}

.options-ps {
  position: absolute;
  border-radius: 0.25rem;
  display: none;
  list-style: none;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0.25rem;
  background-color: var(--color-section-children);
}

.show {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  float: right;
  background-color: var(--color-section-children);
  z-index: 10;
  overflow-y: scroll;
  max-height: 20rem;
}

.options-ps li {
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.options-ps li h4 {
  font-weight: 400;
}

.options-ps li:active,
.options-ps li:focus,
.options-ps li:hover {
  background: var(--color-sectionbg);
  cursor: pointer;
}

.options-ps li[aria-selected="true"] {
  background: var(--color-sectionbg);
  border-radius: 0.25rem;
}

.options-ps li[aria-selected="true"] small {
  color: #fff;
}

.orion__pair-search-container {
  position: sticky;
  top: 0;
}

.option-name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
