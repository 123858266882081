.orion__signal-card {
  padding: 0.75rem;
  background-color: var(--color-section-children);
  gap: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
}

.orion__signal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.orion__pair-description {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.orion__time-opened {
  color: var(--color-subtext);
}

.orion__signal-content {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.orion__content-container-partial {
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(199, 148, 6, 0.05);
}

.orion__content-container-buy {
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  padding: 0.5rem;
  gap: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(6, 203, 9, 0.05);
}

.orion__content-column-name {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.orion__content-column-text {
  font-size: 12px;
  color: var(--color-subtext);
}

.orion__content-separator {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.orion__column {
  display: block;
}

.orion__separator-name {
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-size: 14px;
}

@media (max-width: 760px) {
  .orion__signal-content {
    flex-direction: column;
    gap: 0.25rem;
  }
}
