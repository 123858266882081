h1 {
    color: var(--color-lighttext);
}

.orion__login-form {
    display: flex;
    flex-direction: column;
    gap: .625rem;
}

.orion__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: fit-content;
    justify-content: space-between;
}

.orion__warning {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .25rem;
    background-color: var(--color-alert);
    width: 100%;
    padding: .5rem;
    border-radius: .25rem;
}

.orion__warning small {
    color: white;
}

.orion__warning-icon {
    width: 20px;
    color: var(--color-redtext);
    color: white;
}

.orion__loading {
    margin: 0rem auto;
}

.orion__login-helpers {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.orion__login-helpers small {
    color: var(--color-brandpink);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;

}