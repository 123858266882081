.orion__simulator-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.orion__result-field {
  width: 100%;
  background-color: var(--color-section-children);
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  padding: 0.875rem;
}

.orion__result-separator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;
  overflow: hidden;
}

.orion__result-separator small {
  color: var(--color-text);
}

.orion__value-negative {
  color: var(--color-redtext);
  text-align: center;
}

.orion__value-positive {
  color: var(--color-greentext);
  text-align: center;
}

hr {
  height: 70px;
  color: var(--color-sectionbg);
}

@media (max-width: 768px) {
  .orion__result-field {
    flex-direction: column;
    gap: 1rem;
  }

  hr {
    width: 100%;
    height: 1px;
  }
}
