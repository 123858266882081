.orion__favorite-selection {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orion__fs-container {
  margin: 5rem auto;
  width: 55%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.orion__fs-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.orion__fs-column.presentation {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.orion__fs-column.list {
  width: 70%;
}
.orion__fs-presentation-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.orion__fs-presentation-image img {
  height: 80vh;
}

.orion__fs-column h1 {
  color: var(--color-text);
  font-weight: 700;
  font-size: 48px;
}

.orion__fs-column h2 {
  color: var(--color-text);
  font-weight: 700;
  font-size: 24px;
}
.orion__fs-column p {
  font-size: 16px;
}

.orion__fs-button-wrapper {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orion__fs-button-wrapper a {
  font-size: 14px;
  color: var(--color-text);
}
.orion__fs-coin-list-container {
  background-color: var(--color-sectionbg);
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--color-section-border);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.orion__fs-coin-list {
  max-height: 300px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orion__fs-coin {
  background-color: var(--color-section-children);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orion__fs-coin label {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 600;
}

.orion__fs-coin-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.orion__fs-coin-name {
  display: flex;
  flex-direction: column;
}

.orion__fs-coin-name.orion__fs-coin-symbol {
  font-size: 12px;
  color: var(--color-subtext);
}

.orion__fs-checkbox-icon {
  width: 24px;
  color: var(--color-brandpink);
  cursor: pointer;
}

.orion__fs-checkbox-icon.outline {
  display: block;
}
.orion__fs-checkbox-container {
  position: relative;
}
.orion__fs-checkbox {
  all: unset;
  position: absolute;
  inset: 0;
  cursor: pointer;
  outline: 1px;
}

.orion__fs-checkbox-icon.filled {
  display: block;
}

.orion__fs-checkbox:not(:checked) ~ .orion__fs-checkbox-icon.filled {
  display: none;
}

.orion__fs-checkbox:checked ~ .orion__fs-checkbox-icon.outline {
  display: none;
}

.orion__fs-response-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.orion__fs-response-icon {
  color: var(--color-greentext);
  width: 24px;
}

.orion__fs-response-container small {
  color: var(--color-greentext);
  font-weight: 600;
}
