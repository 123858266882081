.orion__su-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.orion__su-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
}
.orion__password-container {
  display: flex;
  position: relative;
}
.orion__password-eye {
  width: 24px;
  position: absolute;
  right: 20px;
  top: 32px;
}
