.orion__landing-content {
  overflow-x: hidden;
}

.orion__landing-hero {
  width: 100vw;
  padding: 80px 240px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2rem;

  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    #020536 0%,
    #020536 32%,
    #ce154e 100%
  );
}

.hero {
  height: fit-content;
  position: relative;
  bottom: -400px;
  margin-top: -400px;
  box-shadow: -1px 0px 20px -2px #00000020;
  -webkit-box-shadow: -1px 0px 20px -2px #00000020;
  -moz-box-shadow: -1px 0px 20px -2px #00000020;
}

.orion__landing-page a {
  text-decoration: none;
}

.hero img {
  width: 70vw;
  border-radius: 10px;
  border: 2px solid var(--color-section-border);
  overflow: hidden;
  pointer-events: none;
}

.title-container {
  display: flex;
  flex-direction: column;
}
.orion__landing-hero h1 {
  color: var(--color-text);
  font-weight: 700;
  font-size: 48px;
}

.orion__landing-hero h2 {
  color: var(--color-text);
  font-weight: 700;
  font-size: 40px;
}

.orion__landing-hero p {
  color: var(--color-text);
  font-weight: 400;
  font-size: 20px;
}

.orion__landing-hero small {
  color: var(--color-text);
  font-weight: 600;
  font-size: 14px;
}

.orion__landing-hero a.underlined {
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text);
  font-size: 14px;
}

.orion__landing-hero-buttons {
  display: flex;
  gap: 0.5rem;
}

.hero-icon {
  width: 32px;
}
.orion__landing-page {
  font-family: "Mulish", sans-serif;
}

.orion__landing-page b.gradient {
  background-size: 100%;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  background-image: var(--text-gradient);

  background-repeat: repeat;

  font-weight: 700;
}

.orion__landing-presentation {
  display: flex;
  margin-top: 300px;
  padding: 80px 240px;
  width: 100vw;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.orion__landing-presentation small {
  font-weight: 700;
  font-size: 18px;
  color: var(--color-brandpink);
}

.orion__presentation-left-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
  color: var(--color-text);
}

.orion__presentation-left-column h3 {
  font-size: 36px;
  color: var(--color-text);

  font-weight: 700;
}

.orion__presentation-left-column p {
  font-size: 16px;
  color: var(--color-text);
}

.orion__presentation-right-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-end;
}

.orion__presentation-right-column p {
  font-weight: 400;
  font-size: 24px;
}

.orion__feature-container {
  position: relative;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  color: var(--color-text);
  width: 400px;
  height: 200px;
  background:
    linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    var(--text-gradient) border-box;
  border-radius: 10px;
  border: 1px solid transparent;
  top: 0;
  justify-content: center;
  transition: 0.25s ease-in-out;
}

.orion__feature-container:hover {
  top: -10px;
  box-shadow: var(--color-pink-shadow) 0px 30px 40px -7px;
  -webkit-box-shadow: var(--color-pink-shadow) 0px 30px 60px -7px;
  -moz-box-shadow: var(--color-pink-shadow) 0px 30px 40px -7px;
}

.orion__feature-container small {
  font-weight: 600;
  color: var(--color-subtext);
}
.orion__feature-icon {
  position: absolute;
  width: 200px;
  right: 0;
  opacity: 0.1;
  color: var(--color-text);
}

.signup-icon {
  width: 18px;
}

.orion__landing-nav-logo {
  width: 130px;
  pointer-events: none;
}

.orion__signal-section {
  padding: 80px 240px;
  display: flex;
  flex-direction: row;
  height: 100vh;
  gap: 2rem;
  color: var(--color-text);
}

.orion__signal-section h1 {
  color: var(--color-text);
  font-size: 24px;
}

.orion__signal-list {
  background-color: var(--color-sectionbg);
  border: 2px solid var(--color-section-border);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  height: 80vh;
  width: 150%;
  overflow: scroll;

  -webkit-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}
.orion__badge {
  width: 100px;
  pointer-events: none;
}
.orion__signal-list-right-column {
  position: relative;
  width: 100%;
}

.orion__signal-icon {
  border-radius: 50%;
  pointer-events: none;
}

.orion__signal {
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-section-children);
  padding: 16px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.orion__signal-tags {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.orion__signal-tag {
  height: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.5rem;
  background-color: var(--color-section-children);
  border-radius: 50px;
  padding: 14px;
  align-items: center;
  border: 2px solid var(--color-section-border);
}

.orion__signal-tag-name {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.orion__signal-tag-name small {
  color: var(--color-text);
}

.orion__signal-tag-name img {
  width: 18px;
}

.orion__signal-tag-profit {
  color: var(--color-greentext);
  font-weight: 600;
}
.orion__signal-data-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}

.orion__signal-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.orion__signal-info p {
  font-size: 12px;
}
.orion__signal-info small {
  font-size: 10px;
}
.orion__signal-info img {
  width: 18px;
}

.orion__signal-name {
  display: flex;
  flex-direction: column;
}

.orion__signal-data {
  flex: 1 1 80px;
}

.orion__signal-data p {
  font-size: 12px;
}

.orion__signal-data small {
  color: var(--color-subtext);
  font-size: 10px;
}

.orion__signal-data p.positive {
  color: var(--color-greentext);
}

.orion__signal-data p.negative {
  color: var(--color-redtext);
}

.orion__signal-list-description {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.orion__signal-list-description small {
  size: 16px;
  color: var(--color-subtext);
}

.orion__plan-section {
  padding: 48px 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.orion__plan-section-benefit {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.benefit-icon {
  width: 18px;
  color: var(--color-greentext);
}

.orion__plan-section-title {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.orion__plan-section-title h3 {
  color: var(--color-text);
  font-size: 32px;
  font-weight: 700;
}
.orion__plan-section-title p {
  color: var(--color-text);
  font-size: 16px;
}

.orion__plan-cards-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-end;
}
.orion__plan-card {
  position: relative;
  background-color: var(--color-bg);
  border-radius: 10px;
  border: 2px solid var(--color-section-border);
  padding: 40px;
  display: flex;
  gap: 0.625rem;
  flex-direction: column;
  width: 260px;
  height: 345px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  transition: 0.25s ease-in-out;
}
.orion__plan-card.best {
  border: 2px solid var(--color-brandpink);
  height: 375px;
}

.orion__plan-card-header small {
  font-weight: 600;
  color: var(--color-text);
}

.dashed {
  text-decoration: line-through;
}
.orion__plan-card-header {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 16px;
}
.orion__plan-card-header h1 {
  font-size: 48px;
  font-weight: 700;
  color: var(--color-text);
}
.orion__plan-card-header p {
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  color: var(--color-brandpink);
}

.orion__plan-card-banner {
  background-color: #07b458;
  color: var(--color-text);
  font-weight: 700;
  padding: 14px;
  width: 80%;
  position: absolute;
  border-radius: 50px;
  top: -20px;
}
.orion__price-text-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.orion__price-text-container p {
  font-size: 12px;
  color: var(--color-subtext);
}
.orion__plan-card-discount {
  padding: 0.3rem;
  background-color: var(--color-greentext-60);
  border-radius: 4px;
  width: fit-content;
}

.orion__price-container {
  display: flex;
  flex-direction: column;
  height: 50px;
  gap: 0.5rem;
  align-items: center;
}
.orion__plan-card-discount small {
  color: var(--color-greentext);
}
.orion__plan-card-description {
  justify-content: center;
}

.orion__plan-card-description small {
  color: var(--color-subtext);
  font-weight: 400;
}
.orion__plan-card-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.orion__plan-card-button-wrapper small {
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
}

.orion__signal-list-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3rem;
}
.orion__signal-list-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.orion__signal-list-header-text h2 {
  font-size: 32px;
  font-weight: 700;
}
.orion__signal-list-header-text p {
  font-size: 18px;
}

.orion__presentation-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.orion__presentation-button a {
  color: white;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.orion__landing-hr hr {
  padding: 0px 240px;
  height: 2px;
  background-color: var(--color-lightblue);
}

.orion__landing-hr {
  padding: 0px 240px;
}

.orion__phone-image {
  width: 100%;
}

p {
  color: var(--color-text);
}

.orion__gradient-section {
  background-image: linear-gradient(
    168deg,
    #a40e3c 0%,
    #020536 40%,
    #020536 67%,
    #a40e3c 99%
  );
}

.orion__features-section {
  padding: 80px 240px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: visible;
}

.orion__features-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.orion__features-header-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orion__features-header-text small {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-text);
}

.orion__features-header-text p {
  font-weight: 400;
  font-size: 18px;
  width: 70%;
  color: var(--color-text);
}

.orion__carousel-buttons-wrapper {
  display: flex;
  gap: 0.25rem;
}
.orion__carousel-button {
  background-color: var(--color-bg);
  color: var(--color-brandpink);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}

.orion__carousel-wrapper {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.orion__carousel-wrapper::-webkit-scrollbar {
  display: none;
}
.orion__carousel-card-container {
  min-width: 300px;
  min-height: 200px;
  background-color: transparent;
  border-radius: 10px;
}

.orion__carousel-card {
  min-width: 300px;
  min-height: 200px;
  border-radius: 8px;
  background: rgb(2, 5, 54, 0.6);
  top: 0;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem;
  transition: 0.2s ease-in-out;
  position: relative;
}

.orion__carousel-card:hover {
  background-color: rgb(2, 5, 54, 0.6);
  transition: 0.2s ease-out;
  border: 2px solid var(--color-brandpink);
}

.orion__carousel-card small {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-brandpink);
}

.orion__carousel-card p {
  font-size: 20px;
}

.orion__faq-section {
  width: 100%;
  padding: 80px 240px;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.orion__faq-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.orion__faq-header small {
  font-size: 20px;
  color: var(--color-brandpink);
  font-weight: 600;
}

.orion__faq-header h3 {
  font-size: 32px;
  color: var(--color-text);
  font-weight: 700;
}

.orion__faq-header p {
  font-size: 16px;
  color: var(--color-text);
}

.orion__faq-header a {
  margin-top: 32px;
  font-size: 16px;
  color: var(--color-text);
}

.orion__faq-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.orion__testimonials-section {
  margin-top: -200px;
  position: relative;
  padding: 80px 240px;
  bottom: -200px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.orion__testimonials-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.orion__testimonials-header h1 {
  font-size: 40px;
  font-weight: 500;
  color: var(--color-text);
  text-align: center;
}

.orion__testimonials-header p {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-brandpink);
}

.orion__testimonials-cards-wrapper {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.orion__testimonial-card {
  width: 20rem;
  background-color: var(--color-text);
  padding: 32px;
  padding-bottom: 24px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.orion__testimonial-card p {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-lighttext);
}

.orion__testimonial-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orion__testimonial-rating {
  display: flex;
  flex-direction: row;
}

.rating-icon {
  width: 24px;
  color: var(--color-brandyellow);
}

.orion__about-section {
  background-color: #0f153a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
  padding: 48px 240px;
  width: 100%;
}

.orion__about-image {
  max-width: 500px;
  background-color: black;
  border-radius: 20px;
  pointer-events: none;
}

.orion__store-image {
  max-width: 500px;
  background-color: var(--color-sectionbg);
  border-radius: 20px;
  pointer-events: none;
}

.orion__about-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.orion__about-content-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.orion__about-content img {
  width: 250px;
  pointer-events: none;
}

.orion__contact-section {
  padding: 80px 240px;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.orion__contact-section-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
}

.orion__contact-section-header small {
  font-size: 20px;
  color: var(--color-brandpink);
  font-weight: 600;
}

.orion__contact-section-header h1 {
  font-size: 40px;
  font-weight: 600;
  color: var(--color-text);
  text-align: center;
  width: 50%;
}

.orion__contact-section-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.orion__lp-contact-info {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.orion__lp-contact-info p {
  text-align: left;
  font-size: 16px;
}

.orion__contact-info-content {
  display: flex;
  gap: 0.5rem;
}

.orion__contact-icon {
  color: var(--color-text);
  width: 24px;
}

.orion__lp-contact-info hr {
  height: 1px;
  color: var(--color-section-border);
}

.orion__contact-form {
  background-color: var(--color-sectionbg);
  border: 1.5px solid var(--color-section-border);
  padding: 2rem;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.orion__contact-form p {
  font-size: 20px;
  text-align: center;
  width: 70%;
}

.orion__contact-form button {
  border: none;
  background-color: var(--color-brandpink);
  width: 100%;
  border-radius: 4px;
  padding: 12px;
  color: var(--color-text);
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: 0.25s ease-in-out;
  text-align: center;
  justify-content: center;
  border: none;
}

.orion__contact-form button:hover {
  color: var(--color-text);
  background-color: var(--color-brandorange);
  color: var(--color-lighttext);
}

.orion__store-section {
  padding: 80px 360px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  color: var(--color-text);
  background-color: var(--color-sectionbg);
}

.orion__store-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.orion__store-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 1rem;
}

.orion__store-header h3 {
  font-size: 36px;
  font-weight: 700;
}

.orion__store-header p {
  max-width: 600px;
}

.orion__landing-title {
  font-weight: 700;
  font-size: 18px;
  color: var(--color-brandpink);
}

@media only screen and (max-width: 1000px) {
  .orion__landing-hero {
    padding: 80px 20px;
  }
  .orion__landing-hero h1 {
    font-size: 48px;
  }
  .orion__landing-hero p {
    max-width: 100%;
    font-size: 16px;
    inline-size: 300px !important;
    text-wrap: balanced;
  }

  .orion__landing-hero-buttons {
    flex-direction: column;
    align-items: center;
  }

  .hero {
    margin-top: -200px;
    padding-bottom: 200px;
    display: none;
  }
  .orion__landing-presentation {
    flex-direction: column;
    width: 100%;
    padding: 80px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0;
  }
  .orion__presentation-left-column {
    padding: 0;
    width: 100%;
    align-items: normal;
    justify-content: normal;
  }

  .orion__presentation-left-column h3 {
    width: 100%;
    font-size: 32px;
    text-wrap: balance;
  }

  .orion__presentation-left-column p {
    width: 100%;
    text-wrap: balance;
  }
  .orion__presentation-right-column {
    align-items: center;
  }
  .orion__presentation-button {
    flex-direction: column !important;
  }

  .orion__features-section {
    padding: 80px 30px !important;
    justify-content: flex-start;
  }
  .orion__features-header {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 2rem;
  }
  .orion__features-header-text p {
    width: 100%;
  }

  .orion__signal-section {
    padding: 80px 30px;
    flex-direction: column;
    gap: 2rem;
    height: fit-content;
  }

  .orion__signal-list {
    position: relative !important;
    height: 500px;
    width: 100%;
  }

  .orion__signal {
    width: 100%;
  }

  .orion__plan-section {
    position: relative !important;
    padding: 48px 48px;
    flex-direction: column;
  }
  .orion__plan-cards-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .orion__plan-card {
    width: 100%;
    height: 300px;
  }
  .orion__plan-card.best {
    height: 350px;
  }
  .orion__testimonials-section {
    margin-top: 0 !important;
    bottom: 0 !important;
    padding: 40px 80px !important;
  }
  .orion__gradient-section {
    margin-bottom: 0 !important;
  }

  .orion__testimonials-cards-wrapper {
    flex-direction: column !important;
  }

  .orion__faq-section {
    display: flex;
    flex-direction: column;
    padding: 80px 40px !important;
  }

  .orion__faq-header {
    width: 100% !important;
  }

  .orion__about-section {
    flex-direction: column-reverse !important;
    padding: 80px 40px !important;
  }

  .orion__about-image {
    width: 100% !important;
    max-width: 600px !important;
    align-self: center;
  }

  .orion__store-image {
    width: 100% !important;
    max-width: 600px !important;
    align-self: center;
  }

  .orion__about-content-text {
    width: 100% !important;
  }

  .orion__contact-section {
    flex-direction: column !important;
    padding: 80px 40px !important;
  }

  .orion__contact-section-header h1 {
    text-align: center;
    align-items: center;
    width: 100% !important;
  }

  .orion__contact-section-content {
    flex-direction: column !important;
    align-items: center;
  }

  .orion__lp-contact-info {
    align-items: center;
  }
  .orion__lp-contact-info p {
    text-align: center !important;
  }

  .orion__contact-info-content p {
    text-align: left !important;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__landing-hero,
  .orion__landing-presentation,
  .orion__features-section,
  .orion__signal-section,
  .orion__plan-section,
  .orion__faq-section,
  .orion__about-section,
  .orion__contact-section {
    padding: 80px 360px;
  }

  .orion__signal-list {
    width: 170% !important;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__landing-hero,
  .orion__landing-presentation,
  .orion__features-section,
  .orion__signal-section,
  .orion__plan-section,
  .orion__faq-section,
  .orion__about-section,
  .orion__contact-section {
    padding: 80px 480px;
  }
}

@media only screen and (max-width: 768px) {
  .orion__store-section {
    padding: 40px 20px;
    flex-direction: column;
    gap: 2rem;
  }

  .orion__about-image {
    max-width: 100%;
    height: auto;
  }

  .orion__store-image {
    max-width: 100%;
    height: auto;
  }

  .orion__store-content {
    width: 100%;
    text-align: center;
  }

  .orion__store-header h3 {
    font-size: 28px;
  }

  .orion__store-header p {
    font-size: 16px;
  }

  .orion__default-button {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}
