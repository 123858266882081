.orion__reset-password-container,
.orion__request-password-reset-form {
    display: flex;
    flex-direction: column;
    gap: .625rem;
}

.orion__email-sent {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: .25rem;
}

.orion__email-sent-icon {
    width: 24px;
    color: var(--color-greenlight)
}

.orion__email-sent small {
    color: var(--color-greenlight);
    text-decoration-line: none;
}