.orion__account-section {
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.orion__account-section h2 {
  color: var(--color-text);
  font-weight: 500;
}

.orion__account-section h3 {
  color: var(--color-text);
  font-weight: 400;
}

.orion__data-container {
  width: 100%;
  color: var(--color-text);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orion__change-data {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.title-container {
  min-width: 6rem;
}

.title-container p {
  width: 6rem;
  color: var(--color-subtext);
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.625rem;
}

.orion__data-edit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.orion__data-edit {
  max-width: 40%;
  width: fit-content;
}

.orion__data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 400px) {
  .orion__data-edit {
    max-width: 100%;
  }
}
