.orion__container {
  background-image: url("/src/assets/orion-bg.png");
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.orion__modal {
  min-width: 30vw;
  max-width: 80vw;
  max-height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: white;
  border-radius: 0.625rem;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 2em;
}

.orion__logo {
  width: 150px;
}

.orion__logo-container {
  width: 100%;
  height: fit-content;

  display: table-cell;
  vertical-align: middle;
  text-align: center;

  margin-bottom: 0.25rem;
  cursor: pointer;
}

@media (max-width: 780px) {
  .orion__modal {
    max-width: none !important;
    width: 95vw !important;
    padding: 1.5rem;
  }
}

@media (min-width: 780px) and (max-width: 900px) {
  .orion__modal {
    max-width: none !important;
    width: 60vw !important;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__modal {
    min-width: 25vw !important;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__modal {
    min-width: 20vw !important;
  }
}
