.orion__tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  z-index: 2;
}

.orion__tooltip-icon {
  color: var(--color-subtext);
  width: 20px;
  cursor: help;
  transition: color 0.2s ease;
}

.orion__tooltip:hover .orion__tooltip-icon {
  color: var(--color-brandpink);
}

.orion__tooltip .orion__tooltip-bubble {
  z-index: 2;
  visibility: hidden;
  min-width: 300px;
  background-color: black;
  color: white;
  border-radius: 6px;
  padding: 14px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 1.4;
  opacity: 0;
  transition:
    opacity 0.2s,
    visibility 0.2s;
  margin-top: 10px;
}

.orion__tooltip .orion__tooltip-bubble::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 6px solid transparent;
  border-bottom-color: black;
}

.orion__tooltip:hover .orion__tooltip-bubble {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .orion__tooltip .orion__tooltip-bubble {
    min-width: 200px;
    font-size: 12px;
  }
}
