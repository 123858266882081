.orion__su-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 0.5rem;
  position: relative;
  max-height: 500px;
}

.orion__su-button {
  position: absolute;
  width: 100%;
  bottom: 0;
}
