.orion__dropdown-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.orion__dropdown-container {
  width: 100%;
  position: relative;
}

.orion__dropdown-label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.orion__dropdown-label-wrapper small {
  color: var(--color-lighttext);
  font-weight: 500;
}

.orion__dropdown-label-wrapper.error small {
  color: var(--color-alert);
}
.orion__dropdown {
  border: 0;
  border-radius: 4px;
  background: #fff;
  border: 2px solid var(--color-lighttext);
  padding: 1rem;
  height: 3.125rem;
  gap: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.orion__dropdown.error {
  border: 2px solid var(--color-alert);
}
.orion__dropdown.dark {
  background-color: var(--color-section-children);
  border: none;
  color: var(--color-text);
}

button:hover {
  cursor: pointer;
}

.options-dp {
  border-radius: 0.25rem;
  display: none;
  list-style: none;
  width: 100%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 0.25rem;
}

.options-dp.show {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  margin-top: 40px;
  float: right;
  background-color: #fff;
  overflow-y: scroll;
  max-height: 10rem;
  max-width: fit-content;
  z-index: 10000;
}

.options-dp.show.dark {
  background-color: var(--color-section-children);
}

.options-dp li {
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.options-dp li h4 {
  font-weight: 400;
}

.options-dp li:active,
.options-dp li:focus,
.options-dp li:hover {
  background: var(--color-subtext);
  border-radius: 4px;
  cursor: pointer;
}

.options-dp.dark li:active,
.options-dp.dark li:focus,
.options-dp.dark li:hover {
  background: var(--color-sectionbg);
  border-radius: 4px;
  cursor: pointer;
}

.options-dp li[aria-selected="true"] {
  background: var(--color-brandpink);
  color: white;
  border-radius: 0.25rem;
}

.options-dp li[aria-selected="true"] small {
  color: #fff;
}

.orion__dropdown-icon {
  width: 24px;
}
