.orion__alert-floating-card {
    width: 39rem;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 3.125rem auto;
    gap: 0.625rem;
    background-color: var(--color-alert);
    padding: 2rem;
    border-radius: .25rem;
    color: var(--color-text)
}

.orion__alert-title {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.orion__alert-title h3 {
    font-size: 24px;
    font-weight: 500;
}

.orion__alert-icon {
    width: 1.5rem;
}