.orion__input-container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: 0.125rem;
}

.orion__input-label {
  font-weight: 500;
  color: var(--color-lighttext);
}

.orion__input-label-dark {
  font-weight: 500;
  color: var(--color-text);
}

.orion__input {
  width: 100%;
  font-size: 14px;
  height: 3rem;
  padding: 14px;
  background-color: #fff;
  border: 2px solid var(--color-lighttext);
  border-radius: 4px;
  color: var(--color-lighttext);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.orion__input-error {
  width: 100%;
  font-size: 14px;
  height: 3.125rem;
  padding: 14px;
  background-color: #fff;
  border: 2px solid var(--color-alert);
  border-radius: 4px;
  color: var(--color-lighttext);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.orion__input-dark-error {
  width: 100%;
  font-size: 14px;
  height: 3.125rem;
  padding: 14px;
  background-color: var(--color-section-children);
  border: 2px solid var(--color-redtext);
  border-radius: 4px;
  color: var(--color-text);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.orion__input-dark {
  width: 100%;
  font-size: 14px;
  height: 3.125rem;
  padding: 14px;
  background-color: var(--color-section-children);
  border: 2px solid var(--color-section-children);
  border-radius: 4px;
  color: var(--color-text);
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.orion__input:focus {
  outline: none !important;
  border: 2px solid var(--color-brandpink);
}

.orion__input-error:focus {
  outline: none !important;
  border: 2px solid var(--color-brandpink);
}

.orion__input-dark-error:focus {
  outline: none !important;
  border: 2px solid var(--color-brandpink);
}

.orion__input-dark:focus {
  outline: none !important;
  border: 2px solid var(--color-brandpink);
}

.orion__input:focus + .orion__input-label {
  color: var(--color-brandpink);
}

.input-error {
  color: var(--color-alert);
}

.input-dark-error {
  color: var(--color-redtext);
}
