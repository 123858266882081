.orion__table-container {
  width: 100%;
  background-color: var(--color-sectionbg);
  border-radius: 0.25rem;
  max-height: 27.5rem;
  overflow-y: scroll;
  border: 1px solid var(--color-section-border);
  overflow-x: hidden;
}

.orion__table {
  border-collapse: collapse;
  width: 100%;
}

.orion__table thead tr {
  text-align: left;
  border-bottom: 2px solid var(--color-bg);
}

.orion__table thead {
  position: sticky;
  top: 0;
  background-color: var(--color-sectionbg);
  border-bottom: 2px solid var(--color-bg);
}

.orion__table th {
  color: var(--color-subtext);
  font-weight: 500;
  font-size: 12px;
  padding: 0.5rem 1.25rem;
  width: 100%;
  white-space: nowrap;
}

.orion__table td {
  padding: 0.5rem 1.25rem;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  border-bottom: 2px solid var(--color-bg);
}

.orion__column-positive {
  color: var(--color-greentext);
}

.orion__column-negative {
  color: var(--color-redtext);
}

@media (max-width: 760px) {
  .orion__table-container {
    overflow-x: auto;
  }
}
