.orion__subscription-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-bottom: 0.625rem;
    width: 100%;
}

.orion__price-button {
    width: 100%;
    background-color: var(--color-bg);
    border: 2px solid var(--color-bg);
    border-radius: .25rem;
    display: flex;
    justify-content: space-between;
    height: 5rem;

    align-items: center;
    padding: .875rem;

    position: relative;

    transition: background-color .25s;
    transition: border .25s;
}

.orion__price-button:hover {
    border: 2px solid var(--color-brandpink);
}

.orion__price {
    font-size: 1.25rem;
    font-weight: 500;
}

.orion__duration {
    color: var(--color-text);
    font-weight: 500;
}

.orion__price-button input[type='radio'] {
    all: unset;

    position: absolute;
    inset: 0;

    cursor: pointer;
}

.orion__price-button:has(input:checked) {
    background-color: var(--color-brandpink);
    border: 2px solid var(--color-brandpink);

}