.modalContainer {
  position: relative;
  width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: var(--color-sectionbg);
  border: 1px solid var(--color-section-border);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  z-index: 1001;
  gap: 0.625rem;
}

.modalBackground {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.35);
}

.modal-icon {
  width: 24px;
  color: var(--color-text);
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid var(--color-section-border);
  position: sticky;
  background-color: var(--color-sectionbg);
}

.headerWrapper h1 {
  color: var(--color-text);
}
.titleCloseBtn button {
  background-color: transparent;
  border: none;
}

.body {
  overflow-y: scroll;
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.body p {
  font-size: 14px;
  font-weight: 300;
}

.body p.bold {
  font-weight: 600;
}
