.orion__confirmation-pending {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.orion__confirmation-pending img {
  width: 30%;
}

.orion__confirmation-pending h1 {
  text-align: center;
}

.orion__confirmation-pending p {
  text-align: center;
  color: var(--color-lighttext);
  max-width: 100%;
}

.orion__confirmation-pending small {
  text-align: center;
  color: var(--color-brandpink);
  text-decoration-line: underline;
  cursor: pointer;
}

.orion__email-resent-icon {
  width: 24px;
  color: var(--color-greenlight);
}

.orion__email-resent small {
  color: var(--color-greenlight);
  text-decoration-line: none;
}

.orion__email-resent {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
