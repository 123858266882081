.orion__navbar {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  z-index: 999;
  border-bottom: 1px solid var(--color-section-border);
  background: var(--color-bg);
}

.orion__navbar-links_logo {
  cursor: pointer;
}

.orion__nav-logo {
  width: 120px !important;
}

.orion__navbar-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--color-text);
}

.orion__menu-item {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 1rem;
  border-radius: 50px;
  height: 42px;
  cursor: pointer;
}

.orion__menu-item.active {
  background-color: #431430;
}

.orion__menu-item a {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: white;
  text-decoration: none;
}

.orion__menu-item a:visited {
  color: white;
}

.orion__menu-icon {
  width: 18px;
  color: var(--color-text);
}

.orion__userInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  position: relative;
}

.orion__userInfo-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.orion__userInfo-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orion__userInfo-username {
  color: var(--color-text);
  font-size: 14px;
  font-weight: 400;
}

.orion__menu-toggle {
  display: none !important;
}
.orion__menu-icon-mobile {
  width: 30px;
  color: var(--color-text);
  cursor: pointer;
}

.orion__menu-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-bg);
  width: 90%;
  height: 100vh;
  z-index: 999;
  padding: 30px;
  border-left: 0.5px solid var(--color-section-border);
}

.orion__menu-sidebar ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  transition: 0.2ms ease-in-out;
}

.orion__desktop-logout-text {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-brandpink);
}

.orion__profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.orion__default-button svg path {
  stroke-width: 2;
}

@media screen and (max-width: 769px) {
  .orion__navbar-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    transition: 0.3s ease-in-out;
  }

  .orion__navbar-menu.active {
    right: 0px;
  }

  .orion__navbar-menu li {
    margin-bottom: 25px;
  }

  .orion__menu-toggle {
    display: block !important;
  }

  .orion__logout {
    position: absolute;
    right: 20px;
  }

  .orion__profile-container {
    display: none;
  }

  .orion__user-info-mobile {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__navbar {
    width: 100% !important;
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__navbar {
    width: 100%;
  }
}
