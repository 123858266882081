.orion__tier-icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.orion__tier-icon {
  font-size: 1.2em;
  line-height: 1;
}
.orion__tier-icon-bigger {
  font-size: 2em;
  line-height: 1;
}
