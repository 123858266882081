.orion__checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.orion__checkout img {
  width: 200px;
}

.orion__checkout-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.orion__checkout-text p {
  color: var(--color-lighttext);
}
