.orion__user-config {
  display: flex;
  flex-direction: column;
  padding: 2.5vh 2.5vw;
  gap: 2rem;
  min-height: 80vh;
  justify-content: flex-start;
  align-items: center;
}

.orion__user-config h1 {
  color: var(--color-text);
}

.orion__config-container {
  width: 80%;
  border-radius: 4px;
  background-color: var(--color-sectionbg);
  display: flex;
  overflow: hidden;
  position: relative;
}

.orion__config-sidebar {
  width: 30%;
  border-right: 2px solid var(--color-bg);
  overflow: hidden;
}

.orion__sidebar-item {
  width: 100%;
  min-height: 3rem;
  padding: 1rem;
  color: var(--color-text);
  border-bottom: 2px solid var(--color-bg);
  cursor: pointer;
}

.orion__sidebar-item.selected {
  background-color: var(--color-brandpink);
  cursor: default;
}

.orion__config-section {
  width: 100%;
}

.orion__user-config-header {
  width: 80%;
}

.orion__config-sidebar-mobile {
  position: absolute;
  background-color: var(--color-sectionbg);
  width: 80%;
  height: 100%;
  border-right: 1.5px solid var(--color-section-border);
  z-index: 999;
}

.orion__config-sidebar-mobile .orion__sidebar-item {
  border: none;
}

.orion__config-sidebar-icon {
  width: 30px;
  color: var(--color-text);
  cursor: pointer;
}

.orion__cg-sidebar-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 14px;
}

.orion__user-config-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.orion__user-config-header-icon {
  color: var(--color-text);
  width: 24px;
  display: none;
}

@media only screen and (max-width: 1000px) {
  .orion__user-config {
    padding: 1rem 0;
  }
  .orion__config-sidebar {
    display: none;
  }
  .orion__user-config-header-icon {
    display: block;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .orion__user-config {
    padding: 40px 120px;
  }
}

@media only screen and (min-width: 2560px) {
  .orion__user-config {
    padding: 40px 240px;
  }
}
