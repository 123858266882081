.orion__su-double-form {
  display: flex;
  max-width: 90vw;
  min-width: 50vw;
  justify-content: space-between;
  gap: 2rem;
  flex: 1;
  max-height: 500px;
  height: 500px;
  position: relative;
}

.orion__double-input {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.orion__loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 768px) {
  .orion__su-double-form {
    flex-direction: column;
    overflow: scroll;
  }
  .orion__double-input {
    flex-direction: column;
  }
  .orion__su-button {
    position: relative !important;
  }
}
