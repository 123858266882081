.orion__search-container {
  width: 100%;
  height: 3.125rem;
  position: relative;
  background-color: var(--color-section-children);
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.orion__search-input {
  width: 100%;
  padding: 14px 35px 14px 14px;
  border: none;
  background-color: transparent;
  outline: none;
  color: var(--color-text);
  font-size: 14px;
}

.orion__search-icon {
  color: var(--color-text);
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: -33px;
}
