.orion__datepicker-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  color: var(--color-text);
}

.orion__datepicker-container small {
  color: var(--color-text);
  font-weight: 500;
}

.orion__datepicker {
  width: 100%;
  height: 50px;
  background-color: var(--color-section-children);
  display: block;
  border-radius: 4px;
  font-size: 16px;
  padding: 16px;
  border: none;
  transition: outline 0.5s ease-in-out;

  color: var(--color-text);

  &:active,
  &:focus {
    outline: 1.5px solid var(--color-brandpink);
  }

  &:disabled {
    outline: 1.5px solid var(--color-bg);
  }
}
