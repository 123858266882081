.orion__faq {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.orion__faq-menu {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.orion__faq-menu-item {
  background-color: var(--color-text);
  flex: 1;
  flex-grow: 1;
  max-height: 60px;

  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--color-lighttext);
  font-weight: 600;
  font-size: 16px;
  border: none;
  text-wrap: nowrap;
}
.orion__faq-menu-item.selected {
  background-color: var(--color-brandpink);
  color: var(--color-text);
  font-weight: 600;
}

.orion__faq-questions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.orion__faq-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.orion__title-wrapper {
  position: relative;
  width: 100%;
}

.orion__title-wrapper label {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-text);
}

.orion__title-wrapper label {
  display: flex;
  background-color: var(--color-sectionbg);
  border: 1.5px solid var(--color-section-border);
  border-radius: 4px;
  padding: 14px;
  width: 100%;
}
.orion__title-wrapper input {
  all: unset;
  position: absolute;
  inset: 0;
  cursor: pointer;
  outline: 1px;
}
.orion__answer {
  display: none;
  padding: 14px;
}

.orion__title-wrapper input[type="checkbox"]:checked ~ .orion__answer {
  display: block;
}
