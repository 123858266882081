.orion__button-container {
  width: 100%;
}

.orion__button-filled {
  width: 100%;
  padding: 1rem;
  min-height: 3.125rem;
  background-color: var(--color-brandpink);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  transition: background-color 0.5s;
  cursor: pointer;
  box-shadow: none;
}

.orion__button-filled:hover {
  background-color: var(--color-brandpurple);
}

.orion__button-outlined {
  width: 100%;
  padding: 0.75rem;
  min-height: 3.125rem;
  background-color: white;
  border: 2px solid var(--color-brandpink);
  color: var(--color-brandpink);
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  transition: color 0.5s;
  cursor: pointer;
}

.orion__button-outlined:hover {
  color: var(--color-brandpurple);
  border: 2px solid var(--color-brandpurple);
}

.orion__button-outlined:focus {
  background-color: var(--color-brandpurple);
  color: white;
}

.orion__button-alert {
  width: 100%;
  min-height: 3.125rem;
  background-color: var(--color-text);
  border: 2px solid var(--color-text);
  color: var(--color-alert);
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  transition: color 0.5s;
  cursor: pointer;
}
