.orion__su-double-button {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.orion__su-double-button-icon {
  width: 24px;
}

.orion__su-button {
  width: 100px;
  height: 3.125rem;
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--color-brandpink);
  border: 2px solid var(--color-brandpink);
  border-radius: 4px;
  cursor: pointer;
}
