.orion__email-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.orion__email-confirmation img {
  width: 30%;
}

.orion__email-confirmation h1 {
  text-align: center;
}

.orion__email-confirmation p {
  text-align: center;
  color: var(--color-lighttext);
  max-width: 100%;
}
